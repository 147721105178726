.auth-form {
   width: 520px;
   padding: 56px 40px;
   border-radius: 24px;
   display: flex;
   flex-direction: column;
   gap: 40px;
   background-color: white;
   box-shadow: 8px 4px 44px 0 rgba(12, 19, 71, 0.2);
}

.auth-form__header {
   display: flex;
   justify-content: center;
}

.auth-form__link {
   padding-bottom: 12px;
   padding-right: 24px;
   border-bottom: 3px solid rgba(217, 220, 231, 1);
   font-weight: 700;
   font-size: 28px;
   line-height: 28px;
   color: rgba(217, 220, 231, 1);
   transition: all 0.3s ease;
}

.auth-form__link_registration {
   padding-right: 0;
   padding-left: 24px;
}

.auth-form__link_active {
   padding-right: 0;
   border-color: rgba(85, 112, 253, 1);
   color: rgba(41, 40, 72, 1);
}

.auth-form__link_registration.auth-form__link_active {
   padding-left: 0;
}

.auth-form__body {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.auth-form__footer {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.auth-form__forgot-password-link {
   align-self: center;
   font-size: 18px;
   line-height: 18px;
   color: rgba(85, 112, 253, 1);
}