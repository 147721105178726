.select {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.select__label {
   font-weight: 400;
   font-style: 14px;
   line-height: 14px;
   color: rgba(132, 131, 154, 1);
}

.select__select {
   position: relative;
   max-width: 412px;
}

.select__selected-value {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 5px;
   min-height: 64px;
   padding: 22px 16px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: rgba(240, 240, 242, 1);
   font-weight: 400;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.select__selected-value:hover {
   background-color: rgba(221, 226, 253, 1);
}

.select.active .select__selected-value {
   border-radius: 12px 12px 0 0;
}

.select__selected-value svg {
   transition: 0.2s ease;
}

.select.active .select__selected-value svg {
   transform: rotate(-180deg);
}

.select__selected-value path {
   stroke: rgba(85, 112, 253, 1);
}

.select__list {
   position: absolute;
   z-index: 10;
   left: 0;
   top: 100%;
   width: 100%;
   max-height: 190px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-top: 0;
   border-radius: 0 0 12px 12px;
   overflow-x: hidden;
   overflow-y: auto;
   visibility: hidden;
   opacity: 0;
}

.select__list::-webkit-scrollbar {
   width: 8px;
}

.select__list::-webkit-scrollbar-track {
   background: rgba(240, 240, 242, 1);
   border-bottom: 1px solid rgba(217, 220, 231, 1);
   border-bottom-right-radius: 12px;
}

.select__list::-webkit-scrollbar-thumb {
   background-color: rgba(85, 112, 253, 1);
   border-radius: 4px;
}

.select.active .select__list {
   visibility: visible;
   opacity: 1;
}

.select__list-item {
   padding: 22px 16px;
   background-color: rgba(240, 240, 242, 1);
   font-weight: 400;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
   cursor: pointer;
   transition: all 0.3s ease;
}

.select__list-item:not(:first-child) {
   border-top: 1px solid rgba(217, 220, 231, 1);
}

.select__list-item:hover,
.select__list-item.selected {
   background-color: rgba(221, 226, 253, 1);
}