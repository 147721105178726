.auth-layout {
   position: relative;
   min-height: 100vh;
   padding: 55px 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: linear-gradient(rgba(127, 107, 211, 1), rgba(85, 112, 253, 1));
}

.logo {
   position: absolute;
   top: 40px;
   left: 20px;
}

@media screen and (max-width: 991.98px) {
   .logo {
      top: 10px;
      left: 10px;
   }
}