.input {
   display: flex;
   flex-direction: column;
}

.input__label {
   margin-bottom: 12px;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   color: rgba(132, 131, 154, 1);
}

.input__container {
   position: relative;
}

.input__input {
   width: 100%;
   max-width: 440px;
   min-height: 65px;
   padding: 23px 16px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: rgba(240, 240, 242, 1);
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.input_password .input__input {
   padding: 23px 40px 23px 16px;
}

.input.error .input__input {
   border-color: rgba(239, 56, 38, 1);
}

.input__input:focus {
   border-width: 0 0 2px 0;
   border-bottom-color: rgba(85, 112, 253, 1);
}

.input.error .input__input:focus {
   border-bottom-color: rgba(239, 56, 38, 1);
}

.input__input::placeholder {
   font-size: 18px;
   line-height: 18px;
   color: rgba(217, 220, 231, 1);
}

.input__password-visibility-toggle {
   display: none;
   position: absolute;
   top: 50%;
   right: 16px;
   transform: translateY(-50%);
}

.input_password .input__password-visibility-toggle {
   display: block;
}

.input__error-message {
   margin-top: 4px;
   font-size: 14px;
   line-height: 14px;
   color: rgba(239, 56, 38, 1);
}