.modal {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 100;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: linear-gradient(rgba(30, 37, 72, 0.4), rgba(30, 37, 72, 0.28));
   visibility: hidden;
   opacity: 0;
   transition: 0.1s ease;
}

.modal.active {
   visibility: visible;
   opacity: 1;
}

.modal__body {
   position: relative;
   min-width: 520px;
   max-width: 520px;
   padding: 56px 40px;
   display: flex;
   flex-direction: column;
   gap: 40px;
   border-radius: 24px;
   background-color: white;
   box-shadow: 8px 4px 44px 0 rgba(12, 19, 71, 0.2);
}

.modal__close-btn {
   position: absolute;
   right: 23px;
   top: 23px;
}

.modal__title {
   align-self: center;
   font-weight: 700;
   font-size: 29px;
   line-height: 28px;
   color: rgba(41, 40, 72, 1);
}

