.reset-password-form {
   width: 520px;
   padding: 56px 40px;
   border-radius: 24px;
   display: flex;
   flex-direction: column;
   gap: 40px;
   background-color: white;
   box-shadow: 8px 4px 44px 0 rgba(12, 19, 71, 0.2);
}

.reset-password-form__header {
   display: flex;
   flex-direction: column;
}

.reset-password-form__title {
   align-self: center;
   font-weight: 700;
   font-size: 28px;
   line-height: 28px;
   color: rgba(41, 40, 72, 1);
}

.reset-password-form__body {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.reset-password-form__footer {
   display: flex;
   flex-direction: column;
}