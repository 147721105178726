.add-channel-page-body {
   display: flex;
   gap: 16px;
}

.add-channel-page-link {
   width: 320px;
   height: 220px;
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 12px;
   font-weight: 500;
   font-size: 28px;
   line-height: 28px;
   color: white;
   transition: all 0.3s ease;
}

.add-channel-page-link_whatsapp {
   background-color: rgba(76, 175, 80, 1);
}

.add-channel-page-link_telegram {
   background-color: rgba(3, 155, 229, 1);
}

.add-channel-page-link_whatsapp:hover,
.add-channel-page-link_telegram:hover {
   filter: brightness(110%);
}