.empty-list-message {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 32px;
}

.empty-list-message__text {
   font-size: 18px;
   line-height: 18px;
   color: rgba(132, 131, 154, 1);
}

.empty-list-message__btn:not(:first-child) {
   margin-top: 40px;
}