.qty-select {
   min-width: 124px;
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.qty-select__label {
   font-weight: 400;
   font-style: 14px;
   line-height: 14px;
   color: rgba(132, 131, 154, 1);
}

.qty-select__select {
   min-height: 64px;
   padding: 22px 16px;
   display: flex;
   align-items: center;
   gap: 20px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: rgba(240, 240, 242, 1);
}

.qty-select__btn {
   width: 16px;
   height: 16px;
   display: flex;
   align-items: center;
}

.qty-select__btn path {
   stroke: rgba(85, 112, 253, 1);
}

.qty-select__btn:disabled path {
   stroke: rgba(217, 220, 231, 1);
}

.qty-select__value {
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}