.plan-constructor-item {
   display: flex;
   gap: 16px;
}

.plan-constructor-item__select {
   flex: 0 1 412px;
}

.plan-constructor-item__delete-btn {
   align-self: flex-end;
   flex: 0 0 64px;
   width: 64px;
   height: 64px;
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(239, 56, 38, 1);
   transition: all 0.3s ease;
}

.plan-constructor-item__delete-btn:hover {
   background-color: rgba(247, 92, 78, 1);
}

.plan-constructor-item__delete-btn:disabled,
.plan-constructor-item__delete-btn:disabled path {
   background-color: rgba(217, 220, 231, 1);
   stroke: rgba(132, 131, 154, 1);
}