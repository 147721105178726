.add-whatsapp-page-body {
   display: flex;
   flex-direction: column;
}

.add-whatsapp-page-description {
   margin-bottom: 16px;
   font-size: 18px;
   line-height: 18px;
   color: rgba(132, 131, 154, 1);
}

.add-whatsapp-page-instructions-list {
   margin-bottom: 32px;
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.add-whatsapp-page-instructions__list-item {
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
   display: flex;
   gap: 3px;
}

.add-whatsapp-page-qr-code {
   width: 320px;
   height: 320px;
   padding: 22px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.qr-loader {
   width: 50px;
   aspect-ratio: 1;
   border-radius: 50%;
   border: 8px solid;
   border-color: rgba(85, 112, 253, 1) #0000;
   animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}