.reset-password-email-sent-message {
   max-width: 520px;
   padding: 56px 40px;
   border-radius: 24px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: center;
   background-color: white;
}

.reset-password-email-sent-message__img {
   max-width: 165px;
   margin-bottom: 40px;
}

.reset-password-email-sent-message__title {
   font-weight: 700;
   font-size: 28px;
   line-height: 28px;
   color: rgba(41, 40, 72, 1);
}

.reset-password-email-sent-message__text {
   font-size: 18px;
   line-height: 18px;
   color: rgba(132, 131, 154, 1);
   text-align: center;
}