.toggle {
   display: flex;
   align-items: center;
   gap: 12px;
}

.toggle__label {
   cursor: pointer;
}

.toggle__input {
   display: none;
}

.toggle__toggle {
   width: 32px;
   height: 20px;
   position: relative;
}

.toggle__toggle::before {
   content: "";
   display: block;
   width: 100%;
   height: 16px;
   border-radius: 12px;
   background-color: rgba(217, 220, 231, 1);
   position: absolute;
   z-index: 1;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   transition: all 0.2s ease;
}

.toggle.active .toggle__toggle::before {
   background-color: rgba(196, 232, 227, 1);
}

.toggle__toggle::after {
   content: "";
   display: block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   background-color: rgba(132, 131, 154, 1);
   box-shadow: -1px 1px 2px 0 rgba(19, 26, 25, 0.2);
   position: absolute;
   z-index: 2;
   left: 0;
   top: 0;
   transition: all 0.2s ease;
}

.toggle.active .toggle__toggle::after {
   background-color: rgba(0, 182, 155, 1);
   box-shadow: -1px 1px 2px 0 rgba(32, 139, 123, 0.4);
   left: calc(100% - 20px);
}

.toggle__text {
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
   transition: all 0.2s ease;
}

.toggle.active .toggle__text {
   color: rgba(0, 182, 155, 1);
}