.page {
   height: 100vh;
   padding: 32px 40px;
   display: flex;
   flex-direction: column;
   background-color: rgba(240, 240, 242, 1);
   overflow-y: auto;
}

.page__header {
   display: flex;
   align-items: center;
   gap: 20px;
}

.page__header:not(:last-child) {
   margin-bottom: 48px;
}

.page__return-btn {
   display: flex;
   align-items: center;
}

.page__title {
   font-weight: 700;
   font-size: 32px;
   line-height: 32px;
   color: rgba(41, 40, 72, 1);
}

.page__content {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
}