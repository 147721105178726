.delete-channel-modal-body {
   display: flex;
   justify-content: center;
   gap: 16px;
}

.delete-channel-modal-confirm-btn {
   border-color: rgba(239, 56, 38, 1);
   background-color: rgba(239, 56, 38, 1);
}

.delete-channel-modal-confirm-btn:hover {
   border-color: rgba(247, 92, 78, 1);
   background-color: rgba(247, 92, 78, 1);
}