.navbar {
   flex: 0 1 340px;
   min-width: 230px;
   padding: 40px 0 25px;
   display: flex;
   flex-direction: column;
   background: linear-gradient(rgba(85, 112, 253, 1), rgba(127, 107, 211, 1));
   font-size: 20px;
   line-height: 20px;
   color: white;
}

.navbar__header {
   padding: 0 20px;
}

.navbar__header:not(:last-child) {
   margin-bottom: 44px;
}

.navbar__nav-list {
   padding: 0 8px;
   display: flex;
   flex-direction: column;
   gap: 3px;
}

.navbar__nav-list:not(:last-child) {
   margin-bottom: auto;
}

.navbar__nav-link {
   padding: 15px 12px;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 10px;
   color: white;
   transition: all 0.3s ease;
}

.navbar__nav-link:hover,
.navbar__nav-link.active {
   background-color: rgba(255, 255, 255, 0.2);
}

.navbar__footer {
   padding: 0 8px;
}

.navbar__logout-btn {
   width: 100%;
   font-size: inherit;
   line-height: inherit;
}