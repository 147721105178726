.edit-crm-manager-form {
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.edit-crm-manager-form__footer {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 16px;
}