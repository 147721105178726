.btn {
   min-height: 54px;
   padding: 0 32px;
   border: 2px solid rgba(85, 112, 253, 1);
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 11px;
   background-color: rgba(85, 112, 253, 1);
   font-weight: 500;
   font-size: 18px;
   line-height: 18px;
   color: white;
   transition: all 0.3s ease;
}

.btn svg path {
   transition: all 0.3s ease;
}

.btn:hover {
   cursor: pointer;
   border-color: rgba(114, 136, 252, 1);
   background-color: rgba(114, 136, 252, 1);
}

.btn:active {
   background-color: rgb(101, 121, 224);
}

.btn:disabled {
   border-color: rgba(217, 220, 231, 1);
   background-color: rgba(217, 220, 231, 1);
   color: rgba(132, 131, 154, 1);
}

.btn:disabled svg path {
   stroke: rgba(132, 131, 154, 1);
}

.btn:disabled:hover {
   border-color: rgba(217, 220, 231, 1);
   background-color: rgba(217, 220, 231, 1);
   color: rgba(132, 131, 154, 1);
}

.btn:disabled:hover svg path {
   stroke: rgba(132, 131, 154, 1);
}

.btn_transparent {
   background-color: transparent;
   color: rgba(85, 112, 253, 1);
}

.btn_transparent svg path {
   stroke: rgba(85, 112, 253, 1);
}

.btn_transparent:hover {
   color: white;
}

.btn_transparent:hover svg path {
   stroke: white;
}