.plan-payment-page-body {
   display: flex;
   gap: 20px;
}

.plan-payment-page-constructor {
   flex: 0 1 680px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.plan-payment-page-constructor__period {
   padding: 32px 24px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-payment-page-constructor__title {
   margin-bottom: 24px;
   font-weight: 700;
   font-size: 20px;
   line-height: 20px;
   color: rgba(41, 40, 72, 1);
}

.plan-payment-page-constructor__select .select__select {
   max-width: none;
}

.plan-payment-page-constructor__payment-variants {
   padding: 32px 24px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-payment-page-constructor__payment-variants-list {
   display: flex;
   flex-wrap: wrap;
   gap: 14px;
}

.plan-payment-page-constructor__payment-variant {
   width: 308px;
   height: 152px;
   padding: 16px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: rgba(240, 240, 242, 1);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   transition: all 0.3s ease;
}

.plan-payment-page-constructor__payment-variant:hover {
   cursor: pointer;
   border-color: rgba(85, 112, 253, 1);
}

.plan-payment-page-constructor__payment-variant-icon {
   max-width: 100px;
}

.plan-payment-page-total {
   align-self: flex-start;
   flex: 0 1 320px;
   padding: 32px 24px;
   display: flex;
   flex-direction: column;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-payment-page-total__list {
   margin-bottom: 16px;
   padding-bottom: 16px;
   display: flex;
   flex-direction: column;
   gap: 14px;
   border-bottom: 1px solid rgba(217, 220, 231, 1);
}

.plan-payment-page-total__list-item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.plan-payment-page-total__list-item-info {
   display: flex;
   flex-direction: column;
}

.plan-payment-page-total__list-item-info-name {
   margin-bottom: 8px;
   font-size: 16px;
   line-height: 16px;
   color: rgba(41, 40, 72, 1);
}

.plan-payment-page-total__list-item-info-qty {
   color: rgba(132, 131, 154, 1);
}

.plan-payment-page-total__list-item-price {
   align-self: flex-start;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}