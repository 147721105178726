.phone-input {
   display: flex;
   flex-direction: column;
}

.phone-input__label {
   margin-bottom: 12px;
   font-size: 14px;
   line-height: 14px;
   color: rgba(132, 131, 154, 1);
}

.phone-input__input {
   width: 100% !important;
   min-height: 64px;
   padding: 22px 16px 22px 54px !important;
   border: 1px solid rgba(217, 220, 231, 1) !important;
   border-radius: 12px !important;
   background-color: rgba(240, 240, 242, 1) !important;
   font-size: 18px !important;
}

.phone-input__input:focus {
   border-width: 0 0 2px 0 !important;
   border-bottom-color: rgba(85, 112, 253, 1) !important;
}

.phone-input.error .phone-input__input {
   border-color: rgba(239, 56, 38, 1) !important;
}

.phone-input__input + .flag-dropdown {
   border: none !important;
   border-radius: 12px !important;
   background-color: transparent !important;
}

.phone-input__input + .flag-dropdown .selected-flag {
   width: 42px;
   padding-left: 16px;
}

.phone-input__input + .flag-dropdown .selected-flag:hover,
.phone-input__input + .flag-dropdown .selected-flag.open {
   background-color: transparent !important;
}

.phone-input__input + .flag-dropdown .selected-flag .flag {
   transform: translateY(-2px);
}

.phone-input__error-message {
   margin-top: 4px;
   font-size: 14px;
   line-height: 14px;
   color: rgba(239, 56, 38, 1);
}