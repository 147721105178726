.loader {
   flex: 1 1 auto;
   min-height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(240, 240, 242, 1);
}

.loader__loader {
   width: 50px;
   aspect-ratio: 1;
   border-radius: 50%;
   border: 8px solid;
   border-color: rgba(85, 112, 253, 1) #0000;
   animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}