.profile-data-item {
   padding: 22px 24px;
   display: flex;
   align-items: center;
   gap: 15px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.profile-data-item__name {
   flex: 0 1 336px;
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
}

.profile-data-item__value {
   flex: 0 1 612px;
   margin-right: auto;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
   overflow: hidden;
   text-overflow: ellipsis;
}

.profile-data-item__edit-btn {
   width: 18px;
   height: 18px;
}

.profile-data-item__edit-btn img {
   width: 100%;
}