.radio__label {
   cursor: pointer;
   display: flex;
   align-items: center;
   gap: 12px;
}

.radio__input {
   display: none;
}

.radio__radio {
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
   border: 1px solid rgba(132, 131, 154, 1);
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 0.3s ease;
}

.radio.active .radio__radio {
   border-color: rgba(85, 112, 253, 1);
}

.radio__radio::before {
   content: "";
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background-color: transparent;
   transition: all 0.3s ease;
}

.radio.active .radio__radio::before {
   background-color: rgba(85, 112, 253, 1);
}

.radio__text {
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
   transition: all 0.2s ease;
}