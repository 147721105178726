.plan-page-body {
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.plan-page-current-plan {
   max-width: 422px;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.plan-page-current-plan-data-item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 16px;
   font-size: 16px;
   line-height: 16px;
   color: rgba(41, 40, 72, 1);
}

.plan-page-current-plan-data-item__name {
   color: rgba(132, 131, 154, 1);
}