.select-deal-status-modal-body {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.select-deal-status-modal-select .select__select {
   max-width: none;
}

.select-deal-status-modal-btn {
   align-self: center;
}

.select-deal-status-modal-footer {
   display: flex;
   flex-direction: column;
   align-items: center;
}