.channels-list {
   padding: 16px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   background-color: white;
}

.channels-list__header {
   padding: 8px 16px;
   border-radius: 8px;
   display: flex;
   gap: 10px;
   background-color: rgba(240, 240, 242, 1);
}

.channels-list__column {
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
}

.channels-list__column_channel {
   flex: 0 1 328px;
}

.channels-list__column_phone {
   flex: 0 1 360px;
}

.channels-list__column_status {
   flex: 0 1 236px;
}

.channels-list__column_actions {
   flex: 0 0 32px;
   margin-left: auto;
}

.channels-list__list {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.channels-list-item {
   padding: 20px 16px 0;
   display: flex;
   align-items: center;
   gap: 10px;
}

.channels-list-item:not(:first-child) {
   border-top: 1px solid rgba(217, 220, 231, 1);
}

.channels-list-item__channel {
   display: flex;
   align-items: center;
   gap: 13px;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.channels-list-item__phone {
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.channels-list-item__delete-btn {
   width: 32px;
   height: 32px;
   border-radius: 8px;
   background-color: rgba(239, 56, 38, 1);
   transition: all 0.3s ease;
}

.channels-list-item__delete-btn:hover {
   background-color: rgba(247, 92, 78, 1);
}