.update-crm-page-body {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.update-crm-page-data-item {
   padding: 20px 24px;
   display: flex;
   align-items: center;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.update-crm-page-data-item__name {
   flex: 0 1 336px;
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
}

.update-crm-page-data-item__value {
   flex: 1 1 auto;
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.update-crm-page-data-item__status {
   color: rgba(239, 56, 38, 1);
}

.update-crm-page-data-item__status_active {
   color: rgba(0, 182, 155, 1);
}

.update-crm-page-delete-btn {
   align-self: flex-end;
   font-size: 18px;
   line-height: 18px;
   color: rgba(239, 56, 38, 1);
}

.update-crm-page-delete-btn:hover {
   color: rgba(247, 92, 78, 1);
}