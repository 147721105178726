.edit-profile-data-item-form {
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.edit-profile-data-item-form__status {
   margin-bottom: 20px;
}

.edit-profile-data-item-form__footer {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 16px;
}