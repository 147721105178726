.plan-constructor {
   display: flex;
   gap: 20px;
}

.plan-constructor__container {
   flex: 0 1 680px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.plan-constructor__constructor {
   padding: 32px 24px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-constructor__period {
   padding: 32px 24px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-constructor__title {
   margin-bottom: 24px;
   font-weight: 700;
   font-size: 20px;
   line-height: 20px;
   color: rgba(41, 40, 72, 1);
}

.plan-constructor__body {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.plan-constructor__add-item-btn {
   align-self: flex-start;
   display: flex;
   align-items: center;
   gap: 8px;
   font-size: 18px;
   line-height: 18px;
   color: rgba(85, 112, 253, 1);
   transition: all 0.3s ease;
}

.plan-constructor__add-item-btn path {
   stroke: rgba(85, 112, 253, 1);
   transition: all 0.3s ease;
}

.plan-constructor__add-item-btn:hover,
.plan-constructor__add-item-btn:hover path {
   color: rgba(114, 136, 252, 1);
   stroke: rgba(114, 136, 252, 1);
}

.plan-constructor__total {
   flex: 0 1 320px;
   min-width: 250px;
   align-self: flex-start;
   padding: 32px 24px;
   display: flex;
   flex-direction: column;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   background-color: white;
}

.plan-constructor__total .plan-constructor__title {
   margin-bottom: 24px;
}

.plan-constructor__total-list {
   margin-bottom: 16px;
   padding-bottom: 16px;
   display: flex;
   flex-direction: column;
   gap: 14px;
   border-bottom: 1px solid rgba(217, 220, 231, 1);
}

.plan-constructor__total-list-item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.plan-constructor__total-list-item-info {
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
}

.plan-constructor__total-list-item-price {
   font-size: 18px;
   line-height: 18px;
   color: rgba(41, 40, 72, 1);
}

.plan-constructor__total-price {
   display: flex;
   justify-content: space-between;
   gap: 10px;
}

.plan-constructor__total-price {
   margin-bottom: 20px;
   font-size: 16px;
   line-height: 16px;
   color: rgba(132, 131, 154, 1);
}

.plan-constructor__total-price span {
   font-weight: 700;
   font-size: 20px;
   line-height: 20px;
   color: rgba(85, 112, 253, 1);
}

.total-price-loader {
   display: flex;
   justify-content: center;
   align-items: center;
}

.total-price-loader__loader {
   width: 50px;
   aspect-ratio: 1;
   border-radius: 50%;
   border: 8px solid;
   border-color: rgba(85, 112, 253, 1) #0000;
   animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}

@media screen and (max-width: 991.98px) {
   .plan-constructor {
      flex-direction: column;
   }

   .plan-constructor__container {
      flex: none
   }

   .plan-constructor__total {
      flex: none;
      align-self: stretch;
   }
}
