.add-crm-page-body {
   display: flex;
   gap: 16px;
}

.add-crm-page-link {
   width: 320px;
   height: 220px;
   border: 1px solid rgba(217, 220, 231, 1);
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 12px;
   background-color: white;
   font-weight: 500;
   font-size: 28px;
   line-height: 28px;
   color: white;
   transition: all 0.3s ease;
}

.add-crm-page-link:hover {
   border-color: rgba(85, 112, 253, 1);
}